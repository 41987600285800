// Code generated by stream-gen. DO NOT EDIT.

import { isEqual } from 'lodash';

import { Streamable, StreamSpec } from '.';

export class ModelSpec extends StreamSpec {
  readonly #id: Streamable = 'models';
  #workspace_ids: Array<number>;
  #model_ids: Array<number>;
  #user_ids: Array<number>;
  #since: number;

  constructor(
    workspace_ids?: Array<number>,
    model_ids?: Array<number>,
    user_ids?: Array<number>,
    since?: number,
  ) {
    super();
    this.#workspace_ids = workspace_ids || [];
    this.#model_ids = model_ids || [];
    this.#user_ids = user_ids || [];
    this.#since = since || 0;
  }

  public equals = (sp?: StreamSpec): boolean => {
    if (!sp) return false;
    if (sp instanceof ModelSpec) {
      return (
        isEqual(sp.#workspace_ids, this.#workspace_ids)
        &&
        isEqual(sp.#model_ids, this.#model_ids)
        &&
        isEqual(sp.#user_ids, this.#user_ids)
        &&
        isEqual(sp.#since, this.#since)
      );
    }
    return false;
  };

  public id = (): Streamable => {
    return this.#id;
  };

  public toWire = (): Record<string, unknown> => {
    return {
      workspace_ids: this.#workspace_ids,
      model_ids: this.#model_ids,
      user_ids: this.#user_ids,
      since: this.#since,
    };
  };
}

export class ModelversionSpec extends StreamSpec {
  readonly #id: Streamable = 'modelversions';
  #model_ids: Array<number>;
  #model_version_ids: Array<number>;
  #user_ids: Array<number>;
  #since: number;

  constructor(
    model_ids?: Array<number>,
    model_version_ids?: Array<number>,
    user_ids?: Array<number>,
    since?: number,
  ) {
    super();
    this.#model_ids = model_ids || [];
    this.#model_version_ids = model_version_ids || [];
    this.#user_ids = user_ids || [];
    this.#since = since || 0;
  }

  public equals = (sp?: StreamSpec): boolean => {
    if (!sp) return false;
    if (sp instanceof ModelversionSpec) {
      return (
        isEqual(sp.#model_ids, this.#model_ids)
        &&
        isEqual(sp.#model_version_ids, this.#model_version_ids)
        &&
        isEqual(sp.#user_ids, this.#user_ids)
        &&
        isEqual(sp.#since, this.#since)
      );
    }
    return false;
  };

  public id = (): Streamable => {
    return this.#id;
  };

  public toWire = (): Record<string, unknown> => {
    return {
      model_ids: this.#model_ids,
      model_version_ids: this.#model_version_ids,
      user_ids: this.#user_ids,
      since: this.#since,
    };
  };
}

export class ProjectSpec extends StreamSpec {
  readonly #id: Streamable = 'projects';
  #workspace_ids: Array<number>;
  #project_ids: Array<number>;
  #since: number;

  constructor(
    workspace_ids?: Array<number>,
    project_ids?: Array<number>,
    since?: number,
  ) {
    super();
    this.#workspace_ids = workspace_ids || [];
    this.#project_ids = project_ids || [];
    this.#since = since || 0;
  }

  public equals = (sp?: StreamSpec): boolean => {
    if (!sp) return false;
    if (sp instanceof ProjectSpec) {
      return (
        isEqual(sp.#workspace_ids, this.#workspace_ids)
        &&
        isEqual(sp.#project_ids, this.#project_ids)
        &&
        isEqual(sp.#since, this.#since)
      );
    }
    return false;
  };

  public id = (): Streamable => {
    return this.#id;
  };

  public toWire = (): Record<string, unknown> => {
    return {
      workspace_ids: this.#workspace_ids,
      project_ids: this.#project_ids,
      since: this.#since,
    };
  };
}

